//@author: devin

import { jSith } from "../util/jquery-replacement";
import { S25Const } from "../util/s25-const";
import { S25Util } from "../util/s25-util";
import { S25Help } from "../modules/s25-help/s25.help.service";
import { S25Dateformat } from "../modules/s25-dateformat/s25.dateformat.service";
import { S25DateformatConst } from "../modules/s25-dateformat/s25.dateformat.const";
import { s25Dateparser } from "../modules/s25-dateformat/s25.dateparser.service";
import { S25Datefilter } from "../modules/s25-dateformat/s25.datefilter.service";
import { DataAccess } from "../dataaccess/data.access";
import { SettingsService } from "../services/settings.service";
import { UserprefService } from "../services/userpref.service";
import { UserprefDateBarrierService } from "../services/userpref.date.barrier.service";
import { UserprefStaticCachedService } from "../services/userpref.static.cached.service";
import { LynxService } from "../services/lynx.service";
import { FlsService } from "../services/fls.service";
import { AttachmentService } from "../services/attachment.service";
import { ConfigService } from "../services/config.service";
import { DatetimeService } from "../services/datetime.service";
import { PreferenceService } from "../services/preference.service";
import { ListStateService } from "../services/list.state.service";
import { ListGeneratorService } from "../services/list.generator.service";
import { ListService } from "../services/list.service";
import { AuditService } from "../services/audit.service";
import { CacheRepository } from "../decorators/cache.decorator";
import { ImageService } from "../services/image.service";
import { CustomAttributeService } from "../services/custom.attribute.service";
import { OlsService } from "../services/ols.service";
import { SequenceService } from "../services/sequence.service";
import { DocumentService } from "../services/document.service";
import { SortOrderService } from "../services/sort.order.service";
import { MasterDefinitionService } from "../services/master.definitions/master.definition.service";
import { RoleService } from "../services/role.service";
import { OptService } from "../services/opt.service";
import { RateService } from "../services/rate.service";
import { NotificationPolicyService } from "../services/notification.policy.service";
import { ResourceService } from "../services/resource.service";
import { FavoriteService } from "../services/favorite.service";
import { ViewerMessageService } from "../services/viewer.message.service";
import { TooltipService } from "../services/tooltip.service";
import { PasswordRemindService } from "../services/pasword.remind.service";
import { ContactService } from "../services/contact.service";
import { WelcomeMessageService } from "../services/welcome.message.service";
import { LangService } from "../services/lang.service";
import { InstCssService } from "../services/intscss.service";
import { EmailService } from "../services/email.service";
import { EmailSettingService } from "../services/email.setting.service";
import { LockService } from "../services/lock.service";
import { ObjectPermissionService } from "../services/object.permission.service";
import { SpaceService } from "../services/space.service";
import { PendingObjectService } from "../services/pending.object.service";
import { MasqueradeService } from "../services/masquerade.service";
import { EventService } from "../services/event.service";
import { EventEmailService } from "../services/event.email.service";
import { ResourceSpaceAvailService } from "../services/resource.space.avail.service";
import { ObjectSearchAvailabilityUtil } from "../util/object.search.availability.util";
import { OrganizationService } from "../services/organization.service";
import { PricingService } from "../services/pricing.service";
import { TaxesService } from "../services/taxes.service";
import { GroupService } from "../services/group.service";
import { NameService } from "../services/name.service";
import {
    RsvdSliderService,
    RsvdSliderServiceFormatUtil,
    RsvdSliderServiceModelUtil,
} from "../modules/reservation/detail/service/rsrv.slider.service";
import { RsvdetailServiceUtil } from "../modules/reservation/detail/service/rsrv.detail.service";
import { ReservationService } from "../services/reservation.service";
import { ExecutionEnvironment } from "../services/execution.enviroment";
import { BpeService } from "../modules/bpe/bpe.service";
import { BpeUtil } from "../modules/bpe/bpe.util";
import { TaskService } from "../services/task/task.service";
import { TaskSelectionMap } from "../services/task/task.selection.map";
import { AdvancedSearchUtil } from "../modules/advanced-search/advanced-search-util";
import { LoginService } from "../services/login.service";
import { EmbeddedConfigService } from "../modules/s25-embedding-config/embedded.config.service";
import { RegistrationService } from "../services/registration.service";
import { CalendarService } from "../modules/home/calendar/calendar.service";
import { TaskCalendarService } from "../services/task/task.calendar.service";
import { TaskListService } from "../services/task/task.list.service";
import { DashService } from "../modules/home/dash/service/dash.service";
import { DashLangService } from "../modules/home/dash/service/dash.lang.service";
import { DashLangMap } from "../modules/home/dash/service/dash.lang.map";
import { AlertService } from "../services/alert.service";
import { PersistentSessionService } from "../services/persistent.session.service";
import { MergeSearchService } from "../services/merge.search.service";
import { SearchCriteriaService } from "../services/search/search-criteria/search.criteria.service";
import { PublisherService } from "../services/publisher.service";
import { ModalService } from "../modules/modal/modal.service";
import { ServiceLoader } from "../services/service.loader";
import { PopoverService } from "../modules/s25-popover/popover.service";
import { RelationshipService } from "../services/relationship.service";
import { FreshbooksService } from "../services/freshbooks.service";
import { SpringCacheService } from "../modules/s25-service-portal/spring.cache.service";
import { EventStateChangeService } from "../services/event.state.change.service";
import { EventSync, EventSyncConst } from "../util/event.sync.util";
import { EvddItemApi, EvddItemApiConst } from "../modules/event-details/evdd.item.api";
import { UserService } from "../services/user.service";
import { KeyboardSortableService } from "../services/keyboard.sortable.service";
import { EmbeddingUtil } from "../util/embedding.util";
import { OptUtil } from "../modules/s25-opt/s25.opt.util";
import { FormService } from "../services/form.service";
import { Api } from "../api/api";
import { ObjectDetailsUtil } from "../util/object.details.util";
import { SearchDropdownApi } from "../modules/s25-dropdown/single-select/s25.search.dropdown.component";
import { CopyObjectService } from "../services/copy.object.service";
import { AssignPolicyService } from "../services/assign.policy.service";
import { SAVE_SEARCH_MAP, SEARCH_MODEL_SKIP_LIST, SearchService } from "../services/search/search.service";
import { SearchUtil } from "../services/search/s25.search.util";
import { SearchCriteriaUtil } from "../services/search/search-criteria/search.criteria.util";
import { HierarchyApi } from "../modules/event-type-hierarchy/s25.event.type.hierarchy.component";
import { HierarchyUtil } from "../modules/event-type-hierarchy/s25.event.type.hierarchy.util";
import { EventQuotaService } from "../services/event.quota.service";
import { EventFormConflictRepoService } from "../modules/s25-event-creation-form/occurrences/s25.event.form.conflict.repo.service";
import { EventFormOccurrenceUtil } from "../modules/s25-event-creation-form/occurrences/event.form.occurrence.util";
import { ProfileUtil } from "../modules/s25-datepattern/profile.util";
import { S25ImageService } from "../modules/s25-image/s25.image.service";
import { EventDetailsListService } from "../services/event.details.list.service";
import { ItemColorMappingService } from "../services/item.color.mapping.service";
import { AvailService } from "../services/avail.service";
import { TaskEditService } from "../services/task/task.edit.service";
import { RuleTreeService } from "../services/rule.tree.service";
import { S25DatetimePrefssUtil } from "../modules/s25-datetime-prefs/s25.datetime.prefs.util";
import { EventCreationFormService } from "../services/event.creation.form.service";
import { StandoutClassroomService } from "../services/standout.classroom.service";
import { ReportService } from "../services/report.service";
import { Eval25Service } from "../services/eval25.service";
import { BoardService } from "../modules/s25-swarm-schedule/s25.board.service";
import { BoardOccUtil } from "../modules/s25-swarm-schedule/s25.board.occ.util";
import { BOARD_CONST } from "../modules/s25-swarm-schedule/s25.board.const";
import { MasterDefinitionTagsService } from "../services/master.definitions/master.definition.tags.service";
import { OccurrenceProfileUtil } from "../util/occurrence.profile.util";
import { OccurrenceValidationUtil } from "../util/occurrence.validation.util";
import { BuildingService } from "../services/building.service";
import { StandardScheduleService } from "../services/standard.schedule.service";
import { AriaLive } from "../services/aria.live.service";
import { EventSummaryService } from "../modules/s25-swarm-schedule/s25.event.summary.service";
import { BoardUploadService } from "../modules/s25-swarm-schedule/board.upload.service";
import { MeetingPatternGridService } from "../modules/s25-swarm-schedule/meeting.pattern.grid.service";
import { TypeMapService } from "./type.map.service";
import { StripeService } from "../modules/s25-pricing/s25-payments/stripe.service";
import * as Sentry from "@sentry/angular";
import { TinymceLoader } from "../modules/tinymce/tinymce.loader";
import { PermissionService } from "../services/permission.service";
import { TaskTiersService } from "../services/task/task.tiers.service";
import { EventFormRuleUtil } from "../modules/s25-event-creation-form/s25.event.form.rule.util";
import { AuthService } from "../services/auth.service";
import { S25RuleTreeUtil } from "../modules/s25-rule-tree/s25.rule.tree.util";
import { S25OrgPartitionPrefsService } from "../modules/organization-details/s25.org.partition.prefs.service";
import { TaskViewApi } from "../modules/s25-task-view/s25.task.view.component";
import { TaskNormalizeUtil } from "../services/task/task.normalize.util";
import { TelemetryService } from "../services/telemetry.service";
import { Report } from "../pojo/Report";
import { ModalMap } from "../modules/modal/modal.map";
import { CopyObjectUtil } from "../modules/s25-more-actions/copy.object.util";
import { DeviceService } from "../services/device.service";
import { CordovaService } from "../services/cordova.service";

declare global {
    interface Window {
        angBridge: any;
    }
}

export class AngBridge {
    public static createAngBridge() {
        window.angBridge = window.angBridge || {};

        window.angBridge.TypeMapService = TypeMapService;
        window.angBridge.CacheRepository = CacheRepository;
        window.angBridge.jSith = jSith;
        window.angBridge.S25Const = S25Const;
        window.angBridge.S25Util = S25Util;
        window.angBridge.S25Help = S25Help;
        window.angBridge.S25Dateformat = S25Dateformat;
        window.angBridge.S25DateformatConst = S25DateformatConst;
        window.angBridge.s25Dateparser = s25Dateparser;
        window.angBridge.S25Datefilter = S25Datefilter;
        window.angBridge.DataAccess = DataAccess;
        window.angBridge.SettingsService = SettingsService;
        window.angBridge.UserprefService = UserprefService;
        window.angBridge.UserprefDateBarrierService = UserprefDateBarrierService;
        window.angBridge.UserprefStaticCachedService = UserprefStaticCachedService;
        window.angBridge.LynxService = LynxService;
        window.angBridge.FlsService = FlsService;
        window.angBridge.AttachmentService = AttachmentService;
        window.angBridge.ConfigService = ConfigService;
        window.angBridge.DatetimeService = DatetimeService;
        window.angBridge.PreferenceService = PreferenceService;
        window.angBridge.ListStateService = ListStateService;
        window.angBridge.ListGeneratorService = ListGeneratorService;
        window.angBridge.ListService = ListService;
        window.angBridge.AuditService = AuditService;
        window.angBridge.ImageService = ImageService;
        window.angBridge.S25ImageService = S25ImageService;
        window.angBridge.CustomAttributeService = CustomAttributeService;
        window.angBridge.OlsService = OlsService;
        window.angBridge.SequenceService = SequenceService;
        window.angBridge.DocumentService = DocumentService;
        window.angBridge.SortOrderService = SortOrderService;
        window.angBridge.MasterDefinitionService = MasterDefinitionService;
        window.angBridge.MasterDefinitionTagsService = MasterDefinitionTagsService;
        window.angBridge.RoleService = RoleService;
        window.angBridge.OptService = OptService;
        window.angBridge.RateService = RateService;
        window.angBridge.NotificationPolicyService = NotificationPolicyService;
        window.angBridge.ResourceService = ResourceService;
        window.angBridge.FavoriteService = FavoriteService;
        window.angBridge.ViewerMessageService = ViewerMessageService;
        window.angBridge.TooltipService = TooltipService;
        window.angBridge.WelcomeMessageService = WelcomeMessageService;
        window.angBridge.LangService = LangService;
        window.angBridge.PasswordRemindService = PasswordRemindService;
        window.angBridge.ContactService = ContactService;
        window.angBridge.InstCssService = InstCssService;
        window.angBridge.EmailService = EmailService;
        window.angBridge.EmailSettingService = EmailSettingService;
        window.angBridge.LockService = LockService;
        window.angBridge.ObjectPermissionService = ObjectPermissionService;
        window.angBridge.PermissionService = PermissionService;
        window.angBridge.SpaceService = SpaceService;
        window.angBridge.PendingObjectService = PendingObjectService;
        window.angBridge.MasqueradeService = MasqueradeService;
        window.angBridge.EventService = EventService;
        window.angBridge.EventEmailService = EventEmailService;
        window.angBridge.ResourceSpaceAvailService = ResourceSpaceAvailService;
        window.angBridge.ObjectSearchAvailabilityUtil = ObjectSearchAvailabilityUtil;
        window.angBridge.OrganizationService = OrganizationService;
        window.angBridge.PricingService = PricingService;
        window.angBridge.TaxesService = TaxesService;
        window.angBridge.GroupService = GroupService;
        window.angBridge.NameService = NameService;
        window.angBridge.ReservationService = ReservationService;
        window.angBridge.RsvdSliderService = RsvdSliderService;
        window.angBridge.RegistrationService = RegistrationService;
        window.angBridge.RsvdSliderServiceFormatUtil = RsvdSliderServiceFormatUtil;
        window.angBridge.RsvdSliderServiceModelUtil = RsvdSliderServiceModelUtil;
        window.angBridge.RsvdetailServiceUtil = RsvdetailServiceUtil;
        window.angBridge.ExecutionEnvironment = ExecutionEnvironment;
        window.angBridge.BpeService = BpeService;
        window.angBridge.BpeUtil = BpeUtil;
        window.angBridge.TaskService = TaskService;
        window.angBridge.TaskViewApi = TaskViewApi;
        window.angBridge.TaskSelectionMap = TaskSelectionMap;
        window.angBridge.AdvancedSearchUtil = AdvancedSearchUtil;
        window.angBridge.LoginService = LoginService;
        window.angBridge.EmbeddedConfigService = EmbeddedConfigService;
        window.angBridge.CalendarService = CalendarService;
        window.angBridge.TaskCalendarService = TaskCalendarService;
        window.angBridge.TaskListService = TaskListService;
        window.angBridge.TaskTiersService = TaskTiersService;
        window.angBridge.TaskNormalizeUtil = TaskNormalizeUtil;
        window.angBridge.DashService = DashService;
        window.angBridge.DashLangService = DashLangService;
        window.angBridge.DashLangMap = DashLangMap;
        window.angBridge.AlertService = AlertService;
        window.angBridge.PersistentSessionService = PersistentSessionService;
        window.angBridge.MergeSearchService = MergeSearchService;
        window.angBridge.SearchCriteriaService = SearchCriteriaService;
        window.angBridge.PublisherService = PublisherService;
        window.angBridge.ModalService = ModalService;
        window.angBridge.ServiceLoader = ServiceLoader;
        window.angBridge.PopoverService = PopoverService;
        window.angBridge.RelationshipService = RelationshipService;
        window.angBridge.FreshbooksService = FreshbooksService;
        window.angBridge.SpringCacheService = SpringCacheService;
        window.angBridge.EventStateChangeService = EventStateChangeService;
        window.angBridge.EventSync = EventSync;
        window.angBridge.EventSyncConst = EventSyncConst;
        window.angBridge.EvddItemApi = EvddItemApi;
        window.angBridge.EvddItemApiConst = EvddItemApiConst;
        window.angBridge.UserService = UserService;
        window.angBridge.KeyboardSortableService = KeyboardSortableService;
        window.angBridge.EmbeddingUtil = EmbeddingUtil.getSingleton();
        window.angBridge.OptUtil = OptUtil;
        window.angBridge.FormService = FormService;
        window.angBridge.Api = Api;
        window.angBridge.ObjectDetailsUtil = ObjectDetailsUtil;
        window.angBridge.SearchDropdownApi = SearchDropdownApi;
        window.angBridge.CopyObjectService = CopyObjectService;
        window.angBridge.CopyObjectUtil = CopyObjectUtil;
        window.angBridge.AssignPolicyService = AssignPolicyService;
        window.angBridge.SearchService = SearchService;
        window.angBridge.SaveSearchMap = SAVE_SEARCH_MAP;
        window.angBridge.SearchModelSkipList = SEARCH_MODEL_SKIP_LIST;
        window.angBridge.SearchUtil = SearchUtil;
        window.angBridge.SearchCriteriaUtil = SearchCriteriaUtil;
        window.angBridge.HierarchyApi = HierarchyApi;
        window.angBridge.HierarchyUtil = HierarchyUtil;
        window.angBridge.EventQuotaService = EventQuotaService;
        window.angBridge.EventFormConflictRepoService = EventFormConflictRepoService;
        window.angBridge.EventFormOccurrenceUtil = EventFormOccurrenceUtil;
        window.angBridge.ProfileUtil = ProfileUtil;
        window.angBridge.EventDetailsListService = EventDetailsListService;
        window.angBridge.ItemColorMappingService = ItemColorMappingService;
        window.angBridge.AvailService = AvailService;
        window.angBridge.TaskServiceEdit = TaskEditService;
        window.angBridge.RuleTreeService = RuleTreeService;
        window.angBridge.S25DateTimePrefsUtil = S25DatetimePrefssUtil;
        window.angBridge.EventCreationFormService = EventCreationFormService;
        window.angBridge.StandOutClassroom = StandoutClassroomService;
        window.angBridge.ReportService = ReportService;
        window.angBridge.Eval25Service = Eval25Service;
        window.angBridge.BoardService = BoardService;
        window.angBridge.BoardOccUtil = BoardOccUtil;
        window.angBridge.BoardConst = BOARD_CONST;
        window.angBridge.OccurrenceProfileUtil = OccurrenceProfileUtil;
        window.angBridge.OccurrenceValidationUtil = OccurrenceValidationUtil;
        window.angBridge.BuildingService = BuildingService;
        window.angBridge.StandardScheduleService = StandardScheduleService;
        window.angBridge.AriaLive = AriaLive;
        window.angBridge.EventSummaryService = EventSummaryService;
        window.angBridge.BoardUploadService = BoardUploadService;
        window.angBridge.MeetingPatternGridService = MeetingPatternGridService;
        window.angBridge.StripeService = StripeService;
        window.angBridge.Sentry = Sentry;
        window.angBridge.TinymceLoader = TinymceLoader;
        window.angBridge.EventFormRuleUtil = EventFormRuleUtil;
        window.angBridge.AuthService = AuthService;
        window.angBridge.S25RuleTreeUtil = S25RuleTreeUtil;
        window.angBridge.S25OrgPartitionPrefsService = S25OrgPartitionPrefsService;
        window.angBridge.TelemetryService = TelemetryService;
        window.angBridge.ReportNamespace = Report;
        window.angBridge.ModalMap = ModalMap;
        window.angBridge.DeviceService = DeviceService;
        window.angBridge.CordovaService = CordovaService;
    }
}
